import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import '../App.css';
import PartForm from './PartForm';
import axios from 'axios';
import { Link } from "react-scroll";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Test, QuestionGroup, Question, Option } from 'react-multiple-choice';
import Alert from 'react-popup-alert'

function WeddingInfo() {
    const [contact, setContact] = useState([]);
    const [infos, setInfos] = useState([]);
    const [error, setError] = useState(null);
    const location = useLocation();
    const [layout, setLayout] = useState('inline-evts');
    const [selectedOptions1, setSelectedOptions1] = useState({})
    const [selectedOptions2, setSelectedOptions2] = useState({})
    const [selectedOptions3, setSelectedOptions3] = useState({})
    const [eggOne, setEggOne] = useState(false)
    const [eggTwo, setEggTwo] = useState(false)
    const [eggThree, setEggThree] = useState(false)
    const [counter, setCounter] = useState(0)
    const [alert, setAlert] = useState({
        type: 'error',
        text: 'This is a alert message',
        show: false
      })
    
    function onCloseAlert() {
        setAlert({
        type: '',
        text: '',
        show: false
        })
    }

    useEffect(() => {
        if (selectedOptions1[0] == "1" && selectedOptions2[0] == "1" && selectedOptions3[0] == "2") {
            setAlert({
                type: 'success',
                text: 'Du hast alle Hinweise gefunden und die Frage richtig beantwortet, nenne dem Paar das Codewort "Wassermelone" für eine kleine Überraschung :)',
                show: true
            })
        }
        else if (selectedOptions1[0] != undefined && selectedOptions2[0] != undefined && selectedOptions3[0] != undefined) {
            setAlert({        
                type: 'error',
                text: 'Du hast alle Hinweise gefunden aber die Fragen nicht alle richtig beantwortet. Du kannst es nach Neuladen der Seite nochmal probieren :)',
                show: true
            })
        }
        console.log(selectedOptions1[0])
        console.log(selectedOptions2[0])
        console.log(selectedOptions3[0])
    }, [selectedOptions1, selectedOptions2, selectedOptions3]);
    

    const onSelectedOne = useCallback((options) => {
        if (Object.keys(selectedOptions1).length == 0) {
            setSelectedOptions1(options)
            setEggOne(false)
            setCounter(counter + 1)
        }
    });
    const onSelectedTwo = useCallback((options) => {
        if (Object.keys(selectedOptions2).length == 0) {
            setSelectedOptions2(options)
            setEggTwo(false)
            setCounter(counter + 1)
        }
    });
    const onSelectedThree = useCallback((options) => {
        if (Object.keys(selectedOptions3).length == 0) {
            setSelectedOptions3(options)
            setEggThree(false)
            setCounter(counter + 1)
        }
    });

    const clickedEggOne = e => {
        if (Object.keys(selectedOptions1).length == 0) {
            setEggOne(true)
        }
    };
    const clickedEggTwo = e => {
        if (Object.keys(selectedOptions2).length == 0) {
            setEggTwo(true)
        }
    };
    const clickedEggThree = e => {
        e.stopPropagation()  
        e.preventDefault()  
        if (Object.keys(selectedOptions3).length == 0) {
            setEggThree(true)
        }
    };

    useEffect(() => {
        axios.get(process.env.REACT_APP_HOST_URL + "/api/data/contact", {withCredentials: true}).then((response) => {
            setContact(response.data.desc);
            setError(null);
          })
          .catch(setError);
    }, []);
    useEffect(() => {
        axios.get(process.env.REACT_APP_HOST_URL + "/api/data/infos", {withCredentials: true}).then((response) => {
            setInfos(response.data);
            setError(null);
          })
          .catch(setError);
    }, []);

    useEffect(() => {
        if (location.hash === '') {
          //window.scrollTo(0, 0);
        }
        else {
          const id = location.hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
              element.scrollIntoView(true);
            }
        }
      }); 
    
    return (
      <div className="WeddingInfo">
              <Alert
        header={':O'}
        btnText={'Schließen'}
        text={alert.text}
        type={alert.type}
        show={alert.show}
        onClosePress={onCloseAlert}
        pressCloseOnOutsideClick={true}
        showBorderBottom={true}
        alertStyles={{}}
        headerStyles={{}}
        textStyles={{}}
        buttonStyles={{}}
      />
      <div className="top-image"></div>
        <div className="overall-container">
            <div className="top-container">
                <div className="main-navbar-out">
                    <nav className="main-navbar navbar navbar-expand-lg navbar-dark">
                        <div id="counter">{(counter != 0 ? counter + "/3" : "")}</div>  
                        <div>
                            <Link className="navbar-brand"  activeClass="active" smooth spy to="Startseite">Startseite</Link>
                        </div>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav">
                                <li className="nav-item nav-group">
                                    <Link className="nav-link" activeClass="active" smooth spy to="Infos">Orte<span className="sr-only">(current)</span></Link>
                                </li>
                                <li className="nav-item nav-group">
                                    <Link className="nav-link" activeClass="active" smooth spy to="Teilnahme">Teilnahme</Link>
                                </li>
                                <li className="nav-item nav-group">
                                    <Link className="nav-link" activeClass="active" smooth spy to="Sonstiges">Sonstiges</Link>
                                </li>
                                <li className="nav-item nav-group">
                                    <Link className="nav-link" activeClass="active" smooth spy to="Kontakt">Kontakt</Link>
                                </li>
                            </ul>
                            <a className="navbar-brand" href="/fotos">Fotos</a>
                        </div>  
                    </nav>
                </div>
                <div id="Startseite" className="centered content-block  content-no-bg">
                    <span className="big-text shadowed-text">Anna und Tom heiraten​</span>
                    <br />
                    <br />
                    <span className="medium-text shadowed-text"><a className="fancy-a" href='annatom.ics'>24. August 2024</a></span>    
                </div>
                <div className="middle-image"></div>
                <div className="content-block-out content-bg">
                    <div className="content-block normal-text content-bg letter" dangerouslySetInnerHTML={{__html: infos.letter}}>
                    </div>
                </div>
                <div className="content-block-out content-bg">
                    <div className="content-block normal-text content-bg letter">
                    <VerticalTimeline
                    lineColor='var(--secondary-theme-color)'>
                        <VerticalTimelineElement
                            className="main-timeline"
                            contentStyle={{ background: 'var(--main-theme-color-2)'}}
                            contentArrowStyle={{ borderRight: '7px solid  var(--main-theme-color-2)' }}
                            date="14:45"
                            iconStyle={{ background: 'var(--main-theme-color)' }}
                        >
                            <h3>Standesamtliche Hochzeit</h3>
                            <p>Sammlen am <Link activeClass="active" className="innerLink" smooth spy to="FirstInfoContent">Schwindpavillon</Link> "Beim Tor"</p>
                            
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="secondary-timeline"
                            contentStyle={{ background: 'var(--main-theme-color-2)'}}
                            contentArrowStyle={{ borderRight: '7px solid  var(--main-theme-color-2)' }}
                            date="15:00"
                            iconStyle={{ background: 'var(--creme-white)' }}
                        >
                            <h4>Trauung</h4>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="main-timeline"
                            contentStyle={{ background: 'var(--main-theme-color-2)'}}
                            contentArrowStyle={{ borderRight: '7px solid  var(--main-theme-color-2)' }}
                            date="16:30"
                            iconStyle={{ background: 'var(--main-theme-color)' }}
                        >
                            <h3>Feier</h3>
                            <p><Link activeClass="active" className="innerLink" smooth spy to="FirstInfoContent">Altenburg Teehaus</Link></p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="secondary-timeline"
                            contentStyle={{ background: 'var(--main-theme-color-2)'}}
                            contentArrowStyle={{ borderRight: '7px solid  var(--main-theme-color-2)' }}
                            date="17:00"
                            iconStyle={{ background: 'var(--creme-white)' }}
                        >
                            <h4>Sektempfang</h4>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="secondary-timeline"
                            contentStyle={{ background: 'var(--main-theme-color-2)'}}
                            contentArrowStyle={{ borderRight: '7px solid  white' }}
                            date="17:30"
                            iconStyle={{ background: 'var(--creme-white)' }}
                        >
                            <h4>Fotos</h4>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="secondary-timeline"
                            contentStyle={{ background: 'var(--main-theme-color-2)'}}
                            contentArrowStyle={{ borderRight: '7px solid  var(--main-theme-color-2)' }}
                            date="ab 19:00"
                            iconStyle={{ background: 'var(--creme-white)' }}
                        >
                            <h4>Buffet</h4>
                        </VerticalTimelineElement>
                        </VerticalTimeline>
                    </div>
                </div>
                <div className="content-block-out content-no-bg">
                    <div id="Infos" className="centered content-block content-no-bg text-header-content-block">
                        <span className="big-text shadowed-text text-header">Orte</span>
                    </div>
                </div>
                <div className='row no-padding' id="FirstInfoContent" >
                    <div className='col-sm-8 no-padding'>
                        <a href="https://www.frohburg.de/portal/seiten/schwind-pavillon-ruedigsdorf-900000108-27270.html"><div className="hcc-bg">
                        <div id="egg-three" onClick={clickedEggThree}>    
                <div id="egg-three-display">
                </div>
                <div className={eggThree ? "egg-question" : "hidden"}  id="egg-three-question">
                    <Test onOptionSelect={onSelectedThree} style={{width:"100%"}}>
                    <QuestionGroup questionNumber={0}>
                    <Question>Auf welcher Position spielt Tom beim Handball?</Question>
                    <Option value="0">Rechtsaußen</Option>
                    <Option value="1">Halbrechts</Option>
                    <Option value="2">Kreisläufer</Option>
                    <Option value="3">Mitte</Option>
                    </QuestionGroup>
                    </Test>
                </div>
            </div></div></a>
                    </div>
                    <div className='col-sm-4 no-padding'>
                        <iframe className="gmaps" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=SchwindPavillonFrohburg&amp;ie=UTF8&amp;t=&amp;z=10&amp;iwloc=B&amp;output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
                        </iframe>
                    </div>
                </div>
                <div className="content-block content-no-bg centered" dangerouslySetInnerHTML={{__html: infos.firstInfo}}>
                </div>
                <div className='row' id="SecondInfoContent">
                    <div className='col-sm-8 no-padding'>
                        <a href="https://teehaus-altenburg.de/"><div className="kbl-bg"></div></a>
                    </div>
                    <div className='col-sm-4 no-padding'>
                        <iframe className="gmaps" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=Teehaus+%26+Orangerie+Altenburg&amp;ie=UTF8&amp;t=&amp;z=10&amp;iwloc=B&amp;output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
                        </iframe>
                    </div>
                </div>
                <div className="content-block-out content-bg">
                    <div className="content-block content-bg centered" dangerouslySetInnerHTML={{__html: infos.secondInfo}}>
                </div>
                </div>
                <div id="Teilnahme" className="centered content-block content-no-bg text-header-content-block">
                    <span className="big-text shadowed-text text-header">Teilnahme</span>
                    <br />
                    <div>Wir bitten um Rückmeldung bis zum 30.06 :)</div>
                </div>
                <div className="content-block-out content-bg">
                    <div className="content-block content-bg">
                        <PartForm />
                    <div id="egg-two" onClick={clickedEggTwo}>    
                <div id="egg-two-display">
                🥚
                    </div>
                    <div className={eggTwo ? "egg-question" : "hidden"}  id="egg-two-question">
                        <Test onOptionSelect={onSelectedTwo}>
                        <QuestionGroup questionNumber={0}>
                        <Question>Was hat Anna anfangs studiert?</Question>
                        <Option value="0">Mathe</Option>
                        <Option value="1">Physik</Option>
                        <Option value="2">Germanistik</Option>
                        <Option value="3">Politik</Option>
                        </QuestionGroup>
                        </Test>
                    </div>
                </div>
                </div>
                    </div>
                <div id="Sonstiges" className="centered content-block content-no-bg text-header-content-block">
                    <span className="big-text shadowed-text text-header">Sonstiges</span>
                </div>
                <div className="content-block-out content-bg">
                    <div className="content-block content-no-bg centered" dangerouslySetInnerHTML={{__html: infos.fifthInfo}}>
                    </div>    
                </div>
                <div id="Kontakt" className="centered content-block content-no-bg text-header-content-block">
                    <span className="big-text shadowed-text text-header">Kontakt</span>
                    <div id="egg-one">     
                        <div id="egg-one-display" onClick={clickedEggOne}>
                        </div>
                        <div className={eggOne ? "egg-question" : "hidden"} id="egg-one-question">
                            <Test onOptionSelect={onSelectedOne}>
                                <QuestionGroup questionNumber={0}>
                                <Question>Wie lange sind Anna und Tom bereits zusammen?</Question>
                                <Option value="0">7</Option>
                                <Option value="1">8</Option>
                                <Option value="2">6</Option>
                                <Option value="3">9</Option>
                                </QuestionGroup>
                            </Test>
                        </div>
                    </div>
                </div>        
                <div className="content-block-out content-bg">        
                    <div id="Kontakt" className="content-block normal-text content-bg letter" dangerouslySetInnerHTML={{__html: contact}}>
                    </div>
                </div>
            </div>
        </div>   
      </div>
    );
}

export default WeddingInfo;
