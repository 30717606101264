import React, { useState, useCallback, useMemo, forwardRef } from "react";
import Uploady, { useUploadyContext, useItemFinishListener } from "@rpldy/uploady";
import { useBatchAddListener, useBatchFinishListener } from "@rpldy/uploady";
import { asUploadButton } from "@rpldy/upload-button";

const UploadField = asUploadButton(
    forwardRef(({ onChange, ...props }, ref) => {
        const [text, setText] = useState("Select files");

        useBatchAddListener((batch) => {
            let full_name = ""
            for (const item of batch.items) {
                full_name += item.file.name + " "
            }
            setText(full_name.slice(0, 50));
            onChange(full_name.slice(0, 50));
        });

        useBatchFinishListener(() => {
            setText("Select files");
            onChange(null);
        });

        return (
            <div {...props} ref={ref} id="form-upload-button">
                <button type="button" onClick={() => false} className="photo-upload-button btn">{text}</button>
            </div>
        );
    })
);

const UploadForm = (updatePhotos) => {
    const [fields, setFields] = useState({});
    const [fileName, setFileName] = useState(null);
    const uploadyContext = useUploadyContext();

    useItemFinishListener((item) => {
        updatePhotos.updatePhotos()
    })

    const onSubmit = useCallback(() => {
        uploadyContext.processPending({ params: fields });
    }, [fields, uploadyContext]);

    const onFieldChange = useCallback((e)=> {
        setFields({
            ...fields,
            [e.currentTarget.id]: e.currentTarget.value,
        })
    }, [fields, setFields]);

    const buttonExtraProps = useMemo(() => ({
        onChange:setFileName
    }), [setFileName]);

    return (
        <form className="upload-form">
            <UploadField autoUpload={false} extraProps={buttonExtraProps} />
            <br />
            <button id="form-photo-submit" className="btn btn-primary" type="button" onClick={onSubmit} disabled={!fileName}>Hochladen</button>
        </form>
    );
};


export default UploadForm