import React, { useState, useEffect } from 'react';
import '../App.css';
import axios from 'axios'
import Table from 'rc-table';

function AdminView() {
    const [particpants, setParticpants] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get(process.env.REACT_APP_HOST_URL + "/api/participants/all", {withCredentials: true})
          .then((response) => {
            setParticpants(response.data);
            setError(null);
          })
          .catch(setError);
    }, []);

    const downloadCsv = () => {    
      const element = document.createElement("a");
      const dictionaryKeys = Object.keys(particpants[0]);
      const dictValuesAsCsv = particpants.map(dict => (
        dictionaryKeys.map(key => String(dict[key]).replaceAll("\n", "")).join(';')
      ));
      const result = [dictionaryKeys.join(';'), ...dictValuesAsCsv].join('\n');
      const file = new Blob([result], {type: 'text/plain'});
      element.href = URL.createObjectURL(file);
      element.download = "participants.csv";
      document.body.appendChild(element);
      element.click();
    }
    
    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          width: 100,
        },
        {
          title: 'Kontaktdaten',
          dataIndex: 'contactData',
          key: 'contactData',
          width: 200,
        },
        {
          title: '+1?',
          dataIndex: 'plusOne',
          key: 'plusOne',
          width: 200,
        },
        {
          title: '+1 Name',
          dataIndex: 'plusOneName',
          key: 'plusOneName',
          width: 200,
        },
        {
          title: 'Extrawünsche, ...',
          dataIndex: 'extra',
          key: 'extra',
          width: 200,
        },
        {
          title: 'Teilnahme?',
          dataIndex: 'part',
          key: 'part',
          width: 200,
        },
        {
          title: 'Teilnahme Trauung?',
          dataIndex: 'partKirchlich',
          key: 'partKirchlich',
          width: 200,
          render: (v,r,i) => v+"",
        },
        {
          title: 'Teilnahme Feier?',
          dataIndex: 'partKirchlich',
          key: 'partFeier',
          width: 200,
          render: (v,r,i) => v+"",
        }
      ];

    return (
      <div className="Admin">
        <button className='btn btn-primary' onClick={downloadCsv}>Export as CSV</button>
        <br />
        <br />
        <Table columns={columns} data={particpants} />
      </div>
    );
}

export default AdminView;
