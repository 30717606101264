import React, { useCallback, useState, useEffect } from 'react';
import '../App.css';
import axios from 'axios'
import Gallery from 'react-photo-gallery'
import Uploady from "@rpldy/uploady";
import Carousel, { Modal, ModalGateway } from "react-images";
import UploadForm from './UploadForm';
import UploadProgress from "./UploadProgress"

function Fotos() {
    const [photos, setPhotos] = useState([]);
    const [error, setError] = useState(null);
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
  
    const openLightbox = useCallback((event, { photo, index }) => {
      setCurrentImage(index);
      setViewerIsOpen(true);
    }, []);
  
    const closeLightbox = () => {
      setCurrentImage(0);
      setViewerIsOpen(false);
    };


    const updatePhotos = () => {
        axios.get(process.env.REACT_APP_HOST_URL + "/api/photo/all", {withCredentials: true})
          .then((response) => {
            setPhotos(response.data);
            setError(null);
          })
          .catch(setError);
    }

    useEffect(() => {
        updatePhotos()
    }, []);
    
    let photosForGallery = photos.map(imageEntry => {
        return {
            src: process.env.REACT_APP_HOST_URL + '/files/' + imageEntry.name,
            width: imageEntry.width,
            height: imageEntry.height,
            annotation: imageEntry.annotation
        }
    }).reverse();

    const imgWithClick = { cursor: 'pointer' };

    const renderImage = ({left,
        top,
        key,
        containerHeight,
        index,
        margin,
        direction,
        onClick,
        photo}) => {
        const imgStyle = { margin: margin, display: 'block' };
        if (direction === 'column') {
            imgStyle.position = 'absolute';
            imgStyle.left = left;
            imgStyle.top = top;
        }
        const handleClick = event => {
            onClick(event, { photo, index });
        };
    
        return (
            <div className='image-anno-container'><img
            key={key}
            style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
            {...photo}
            onClick={onClick ? handleClick : null}
            alt="Picture in Gallery"
        /></div>
        );
  
    };
    
    const filterPhotos = useCallback((file) => {
        return file.size < 15242880 && file.type.includes("image");
    }, []);

    return (
      <div className="Fotos">
      <div className="top-image"></div>
        <div className="overall-container">
            <div className="top-container">
                <div className="main-navbar-out">
                    <nav className="main-navbar navbar navbar-expand-lg navbar-dark">
                        <div>
                            <a className="navbar-brand" href="start">Startseite</a>
                        </div>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav">
                                <li className="nav-item nav-group">
                                    <a className="nav-link" href="start#Infos">Infos<span className="sr-only">(current)</span></a>
                                </li>
                                <li className="nav-item nav-group">
                                <a className="nav-link" href="start#Teilnahme">Teilnahme</a>
                                </li>
                                <li className="nav-item nav-group">
                                <a className="nav-link" href="start#Sonstiges">Sonstiges</a>
                                </li>
                                <li className="nav-item nav-group">
                                    <a className="nav-link" href="start#Kontakt">Kontakt</a>
                                </li>
                            </ul>
                            <a className="navbar-brand" href="/fotos">Fotos</a>
                        </div>                              
                    </nav>
                </div>
                <div className="centered content-block content-first content-no-bg">
                    <span className="big-text shadowed-text">Fotogalerie</span>
                </div>
                <div className="content-block-out content-bg">
                    <div className="upload-container content-block content-bg">
                        <Uploady
                            destination={{ url: process.env.REACT_APP_HOST_URL+"/api/photo" }}
                            multiple={true}
                            fileFilter={filterPhotos}
                            withCredentials={true}
                            >
                            <UploadForm updatePhotos={updatePhotos}/>         
                            <UploadProgress />
                        </Uploady>
                    </div>
                </div>
                <div className="gallery-container">
                    <Gallery photos={photosForGallery} renderImage={renderImage} onClick={openLightbox} />;
                    <ModalGateway>
                        {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                            <Carousel
                            currentIndex={currentImage}
                            views={photosForGallery.map(x => ({
                                ...x,
                                srcset: x.srcSet,
                                caption: x.title
                            }))}
                            />
                        </Modal>
                        ) : null}
                    </ModalGateway>

                </div>
            </div>
        </div>
      </div>
    );
}

export default Fotos;
