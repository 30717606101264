import React, { Component } from 'react';
import '../App.css';
import axios from 'axios';


class PartForm extends Component {
  constructor() {
    super();
    this.state = {
        name: '',
        contactData:'',
        plusOne:false,
        plusOneName:'',
        extra:'',
        part:'true',
        partKirchlich:true,
        partFeier:true
    };
    this.stateTwo = {
        valid: true,
        sent_success: false,
        tech_fail: false
    };
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeCheck = e => {
    this.setState({
        [e.target.name]: !this.state[e.target.name]
      });
    };

  onSubmit = e => {
    e.preventDefault();

    this.stateTwo.sent_success = false
    this.stateTwo.tech_fail = false

    const data = {
        name: this.state.name,
        contactData: this.state.contactData,
        plusOne: this.state.plusOne,
        plusOneName: this.state.plusOneName,
        extra: this.state.extra,
        part: this.state.part,
        partKirchlich: this.state.partKirchlich,
        partFeier: this.state.partFeier
    };

    if (this.state.part === 'true' && !this.state.partKirchlich && !this.state.partFeier) {
        this.stateTwo.valid = false
        this.setState(data)
    } else {
        this.stateTwo.valid = true



        axios
        .post(process.env.REACT_APP_HOST_URL + '/api/participants/', data, {withCredentials: true})
        .then(res => {
            this.setState({
                name: '',
                contactData:'',
                plusOne:false,
                plusOneName:'',
                extra:'',
                part:'true',
                partKirchlich:true,
                partFeier:true
            });
            console.log(res.data);
            axios.post(process.env.REACT_APP_HOST_URL + '/api/participants/notify', res.data, {withCredentials: true})    
        })
        .catch(err => {
            console.log(err);
            console.log("Error in CreateResponse!");
            this.stateTwo.tech_fail = true;
        })
        
        this.stateTwo.sent_success = true
    }
  };

  render() {
    return (
        <form onSubmit={this.onSubmit}>
            <div className="teilnhame-container container">
                <div className="row">
                    <div className="col-sm">
                        <div className="form-group">
                        <input type="text" required className="form-control" value={this.state.name} name="name" onChange={this.onChange} id="guest-name" placeholder="Name" />
                        </div>
                        <div className="form-group">
                            <input type="email" className="form-control" value={this.state.contactData} name="contactData" onChange={this.onChange} id="guest-email" placeholder="Email oder Telefon (optional)" />
                        </div>
                        <div className="form-group">
                            <div className="form-check hidden">
                                <input type="checkbox" value="true" checked={this.state.plusOne} name="plusOne" onChange={this.onChangeCheck}  className="form-check-input" id="plus-one" />
                                <label className="form-check-label" htmlFor="exampleCheck1">+1?</label>
                            </div>                        
                        </div>
                        <div className="form-group">
                            <input type="text" className={this.state.plusOne ? 'form-control' : 'form-control hidden'} value={this.state.plusOneName} name="plusOneName" onChange={this.onChange} id="plus-one-name" placeholder="Name der Begleitung" />
                        </div>
                        <div className="form-group">
                            <textarea value={this.state.extra} name="extra" onChange={this.onChange} className="form-control" rows="5" id="guest-message" placeholder="Gibt es beim Essen etwas, das wir beachten sollten (bspw. vegetarisch/vegan, Unverträglichkeiten)?
                            Benötigt ihr eine Unterkunft? Hast du/ihr noch Anmerkungen oder Wünsche? &#128515;​" />
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="form-check">
                            <input className="form-check-input" value="true" onChange={this.onChange} type="radio" name="part" id="part"
                                checked={this.state.part === "true"} />
                            <label className="form-check-label" htmlFor="guest-yes">
                                Hiermit sage ich zu
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" value="false" onChange={this.onChange} type="radio" name="part" id="part" 
                                checked={this.state.part === "false"} />
                            <label className="form-check-label" htmlFor="guest-no">
                                Ich kann leider nicht kommen
                            </label>
                        </div>
                        <div className="form-divider"></div>
                        <div className="form-check">
                            <input type="checkbox" value="true" checked={this.state.partKirchlich} name="partKirchlich" onChange={this.onChangeCheck}  className="form-check-input" id="check-kirche" />
                            <label className="form-check-label" htmlFor="exampleCheck1">Standesamtliche Trauung</label>
                        </div>
                        <div className="form-check">
                            <input type="checkbox" value="true" checked={this.state.partFeier} name="partFeier" onChange={this.onChangeCheck}  className="form-check-input" id="check-feier" />
                            <label className="form-check-label" htmlFor="exampleCheck1">Hochzeitsfeier</label>
                        </div>
                    </div>
                </div>
                <div  className="row">
                    <div className="col-sm-8">
                        <button type="submit" className="btn btn-primary">Absenden</button>
                    </div>
                </div>
                <br />
                <div className='row'>
                    
                {this.stateTwo.valid ? 
                    "" : 
                    <div className='validation-message alert alert-danger'>
                        Bei Zusage, bitte Events anhaken an den du kommen möchtest :)
                        </div>
                    }
                    {!this.stateTwo.sent_success ? 
                    "" : 
                    <div className='validation-message alert alert-success'>
                        Danke für die Rückmeldung!
                    </div>
                    }
                    {!this.stateTwo.tech_fail ? 
                    "" : 
                    <div className='validation-message alert alert-error'>
                        Es ist ein unerwarteter Fehler aufgetreten!
                        </div>
                    }
                </div>
            </div>
        </form>);
  }
}

export default PartForm;

