import React, {useState, useReducer, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import './Login.css';

import axios from 'axios'
import WeddingInfo from './components/WeddingInfo';
import Fotos from './components/Fotos';
import Login from './components/Login';
import useToken from './useToken';
import AdminView from './components/AdminView';

function App() {
  const { token, setToken } = useToken();
  const [ valid, setValid ] = useState(null);
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  

  if ((!valid || valid === null)) {
    console.log("ABC")
    axios.post(process.env.REACT_APP_HOST_URL + "/api/session/check", {}, {withCredentials: true}).then((response) => {
      setValid(response.data.auth);
      })
      .catch((e) => setValid(false));
  }   

  if (valid === null) {
    return ""
  }
  if(!token || !valid) {
    return <Login setToken={setToken} forceUpdate={forceUpdate} />
  }

  return (
    <Router>
      <div>
        <Routes>
          <Route path='/' element={<WeddingInfo/>} />
          <Route path='/start' element={<WeddingInfo/>} />
          <Route path='/fotos' element={<Fotos/>} />
          <Route path='/weddinghandlingadmin' element={<AdminView/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
