import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios'

export default function Login({ setToken, forceUpdate }) {
  const [password, setPassword] = useState();
  const [result, setResult] = useState(null);

  const handleSubmit = async e => {
    e.preventDefault();
    const data =  { 
        username: "user",
        password: password
    }

    axios
    .post(process.env.REACT_APP_HOST_URL + '/api/login', {}, {
      withCredentials: true,
      auth: data
    })
    .then(res => {   
      setResult(res.statusText === 'OK')
      setToken(res.statusText);
      forceUpdate()
    })
    .catch(err => {
      setResult(false)
    })
  }
  
  return(
    <div className="login">
    <div className="login-background-cover">
    <div className="login-container">
      <span className='big-text white-text'>Annas und Toms Hochzeit</span>
      <br />
      <form onSubmit={handleSubmit} className='login-form'>
        <label>
          <input className='form-control' type="password" placeholder="Passwort" onChange={e => setPassword(e.target.value)} />
        </label>
        <div>
          <button className='btn btn-primary' type="submit">Einloggen</button>
        </div>
      </form>
      <br/>
        {result || result === null ? 
                    "" : 
                    <div className='validation-message alert alert-danger'>
                        Die Daten sind nicht korrekt!
                        </div>
                    }
    </div>
    
    </div>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};